//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/fonts";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

// general
body {
  font-family: $baseFont;
  @include font-size(14);
  color: $black;
  background: url("../Images/bg_main.png") 50% 50% repeat-y $bgGrey;
}

h1,
h2 {
  color: $red;
  @include font-size(19);
  margin: 5px 0 10px 0;
}

.iOs-iFrame-fix {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// key visiual
@import "keyVisual";


// page
.page {
  max-width: $maxPageWidth;
  margin: auto;
  position: relative;
  @media only screen and (max-width: $size-M) {
    max-width: 610px;
  }
}

// header
@import "header";

@import "burger-icon";


// nav main
@import "navMain";


// nav sub
@import "navSub";


// nav service
@import "navService";

@import "main";

@import "ce-video";


// temp
.csc-textpic-intext-right-nowrap,
.csc-textpic-intext-right {
  .csc-textpic-imagewrap {
    float: right;
  }
}

// DATEV Video Embedded
.mym-widget-container.mym-widget-video.embedded {
  min-height: 1px !important;
  min-width: 1px !important;
}


// content welcome
@import "contentWelcome";


// content main
@import "contentMain";


// footer
@import "footer";


// extension CSS
@import "dictionary";


// menu pages
@import "menu-pages";
