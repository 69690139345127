//
// Defaul Project Settings
//

$maxPageWidth: 1200px;
$baseFont: Lato, Arial, Helvetica, sans-serif;

$size-XXXL3: 2400px;
$size-XXXL2: 2150px;
$size-XXXL: 1900px;
$size-XL2: 1200px;
$size-XL: 1100px;
$size-L: 1000px;
$size-M: 899px;
$size-S: 620px;
$size-XS: 500px;
