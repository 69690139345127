// main

main {
  display: flex;
  flex-flow: column;
  position: relative;
  z-index: 2;
  min-height: 553px;
  padding: 15px 35px 15px 295px;

  @include font-size(14);

  h1 {
    @include font-size(18);
  }

  @media only screen and (min-width: $size-L) {
    @include font-size(16);

    h1 {
      @include font-size(20);
    }
  }


  @media only screen and (min-width: $size-XL) {
    @include font-size(18);

    h1 {
      @include font-size(24);
    }
  }

  .content-main {
    //max-height: 380px;
    //overflow-y: auto;
  }
  .content-bottom-bar {
    background: url("../Images/white_box_bottom_large.png") right top no-repeat;
    height: 22px;
    //max-width: 570px;
  }

  @media only screen and (max-width: $size-M) {
    max-width: 610px;
    min-height: 200px;
    padding: 0 15px 0 15px;

    .content-main {
      max-height: none;
      overflow: visible;
    }
  }

  @media only screen and (max-width: $size-S) {
    min-height: 100px;
    padding: 5px 10px 20px 10px;
  }

  @media only screen and (max-width: $size-XS) {

    div.csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
      float: none;
    }

    div.csc-textpic-intext-left .csc-textpic-imagewrap,
    div.csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
      margin: 0;
    }

    .csc-textpic-text {
      margin: 0 !important;
    }

    .ce-left .ce-gallery,
    .ce-right .ce-gallery,
    .ce-column {
      float: none;
    }
  }


  // make sure footer is below main hero image
  @media only screen and (min-width: $size-XXXL) {
    min-height: 800px;
  }

  @media only screen and (min-width: $size-XXXL2) {
    min-height: 900px;
  }

  @media only screen and (min-width: $size-XXXL3) {
    min-height: 1000px;
  }

}
