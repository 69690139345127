// nav main

.nav-main {
  background: $red;
  height: 170px;

  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;

    li {

      span {
        color: $white;
        display: none;
        float: right;
        @include font-size(40);
        padding: 0 15px;
        cursor: pointer;
      }

      a {
        color: #b6bac0;
        text-transform: uppercase;
        /*font-weight: bold;*/
        display: block;
        background: url("../Images/main_menu_line.png") no-repeat center top;
        padding: 4px 0 4px 40px;
        width: 360px;
      }

      &:first-child a {
        background: none;
      }

      a.active,
      a:hover {
        color: $white;
      }

      ul {
        display: none;

        li {

          a {
            text-transform: none;
            background: none;
            padding: 0;
            width: auto;
          }

        }

      }

      &.open span {
          transform:rotate(90deg);
      }
    }
  }

  @media only screen and (max-width: $size-XL) {

    ul li a {
      width: 260px;
    }

  }

  @media only screen and (max-width: $size-M) {
    height: auto;
    box-shadow: 3px 3px 4px #333;

    ul {

      li {
        border-top: 1px solid #f4f4f4;

        &:first-child {
          border: none;
        }

        a {
          background: none;
          line-height: 40px;
          @include font-size(18);
          color: $white;
          width: auto;
        }

        span {
          display: block;
        }

        ul {
          padding: 0 10px 10px 70px;
          li {
            border: none;
          }
        }

      }
    }
  }
}
