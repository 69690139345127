//
// Dictionary CSS
//

section.content-main ul.alpha-sitemap-nav,
section.content-main ul.csc-menu {
  list-style: none;
  padding-left: 0;
}

// a-z nav
.alpha-sitemap-nav {
  list-style: none;
  overflow: hidden;

  li {
    float: left;
    margin-left: 8px;
    margin-top: 0 !important;

    &:first-child {
      margin-left: 0;
    }

    a {
      color: $red;
    }
  }
}


// entry list
ul.csc-menu {
  list-style: none;
  margin: 0;

  li {
    display: none;

    h4 {
      margin: 18px 0 5px 0;
      font-size: 30px;
      color: #c2c2c2;
    }

    a {
      color: $red;
    }
  }
}
