//
// Custom Mixins  @mixin
//

@use "sass:math";

// font size in rem with px as base
@mixin font-size($sizeValue: 16) {
  font-size: #{math.div($sizeValue, 16)}rem;
}

// Clearfix
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

// Hide text
@mixin hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// Inline LIs
@mixin list-inline {
  padding-left: 0;
  list-style: none;
  > li {
    display: inline-block;
  }
}

// vertical align
@mixin vertical-align() {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: .1px;
  }
}

// center - vertical and horizontal
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Sprites resizer
@mixin resize-sprite($map, $sprite, $percent) {
  $spritePath: sprite-path($map);
  $spriteWidth: image-width($spritePath);
  $spriteHeight: image-height($spritePath);
  $width: image-width(sprite-file($map, $sprite));
  $height: image-height(sprite-file($map, $sprite));
  @include background-size(ceil($spriteWidth * ($percent/100)) ceil($spriteHeight * ($percent/100)));
  width: ceil($width*($percent/100));
  height: ceil($height*($percent/100));
  background-position: 0 floor(nth(sprite-position($map, $sprite), 2)  * ($percent/100));
}

// Separator for horizontal menus, don't show lines on hover
@mixin nav-separator($color, $height, $left, $right, $bottom) {
  background-color: $color;
  height: $height;
  width: 2px;
  content: "";
  display: block;
  position: absolute;
  left: $left;
  right: $right;
  bottom: $bottom;
}

// Triangle => http://hackingui.com/front-end/10-best-scss-utilities/
@mixin triangle($direction, $size: 6px, $color: #222, $scale: 1) {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  @if ($direction == 'up'){
    border-bottom: $size solid $color;
    border-left: $scale * $size solid transparent;
    border-right: $scale * $size solid transparent;
  } @else if ($direction == 'down'){
    border-top: $size solid $color;
    border-left: $scale * $size solid transparent;
    border-right: $scale * $size solid transparent;
  } @else if ($direction == 'left'){
    border-top: $scale * $size solid transparent;
    border-bottom: $scale * $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == 'right'){
    border-top: $scale * $size solid transparent;
    border-bottom: $scale * $size solid transparent;
    border-left: $size solid $color;
  }
}
