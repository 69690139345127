// key visual

.key-visual {
  position: absolute;
  top: 170px;
  //height: 583px;
  width: 100%;
  text-align: center;
  overflow: hidden;

  img {
    max-width: none;
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width: $size-M) {
    background: #e8e8e8;
    height: 150px;
    top: 0;
    img {
      display: none;
    }
  }
}
