// content Main

section.content-main {
  position: relative;
  background-color: $white;
  padding: 20px 25px 0 25px;

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin: 0 0 1rem 0;
    list-style: square;
    padding-left: 1em;

    li:not(:first-child) {
      margin-top: 4px;
    }
  }

  .ce-intext.ce-right .bodytext {
    max-width: 600px;
  }

  .ce-intext.ce-right .ce-gallery {
    width: 200px;

    img {
      width: 100%;
      max-width: none;
      height: auto;
    }
  }

}
