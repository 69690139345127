//
// Global CSS
//

// ----- HTML-Elemente -----

body {
  margin: 0;
}

img {
  border-style: none;
}

a {
  text-decoration: none;
  color: $red;
}

p {
  margin: 0 0 1em 0;
}

// set some elements responsive
img, object, embed, video {
  /*height: auto;
  max-width: 100%;*/
}

// and the hack for IE8
@media \0screen {
  img, object, embed, video {
    width: auto; /* for ie 8 */
  }
}

// and for video
.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  @media (max-width: 979px) {
    margin-bottom: 20px;
  }
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.no-margin {
  margin: 0 !important;
}

.right-float {
  float: right;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}
