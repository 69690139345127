// nav sub

.nav-sub {
  background: $grey;
  height: 170px;
  flex: 1;
  margin-right: 40px;

  ul {
    list-style: none;
    margin: 0;
    padding: 9px 0;

    li {
      margin-top: 1px;

      a {
        color: $white;
        display: block;
        padding: 4px 0 4px 30px;
        @include font-size(13);

        &:hover,
        &.active {
          background: $red;
        }

      }
    }
  }

  @media only screen and (max-width: $size-M) {
    display: none;
  }
}
