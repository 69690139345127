// header

.header-main {
  overflow: hidden;

  .logo {
    float: left;
    margin: 30px 70px 0 90px;

    img:last-child {
      display: none;
    }

  }

  // nav area
  .nav-area {

    @media only screen and (max-width: $size-M) {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 150px;
      z-index: 9;
    }

    @media only screen and (min-width: $size-M) {
      display: flex !important;  // bring back main nav even if toggle hidden in mobile view
    }
  }

  #nav-mobile-icon {
    position: absolute;
    width: 52px;
    height: 42px;
    top: 54px;
    left: 30px;
    cursor: pointer;
    display: none;
  }

  @media only screen and (max-width: $size-M) {
    padding: 28px 0 19px 0;
    background: #e8e8e8;

    .logo {
      float: none;
      margin: auto;
      width: 150px;

      img:last-child {
        display: block;
        width: 150px;
        height: auto;
      }

      img:first-child {
        display: none;
      }

    }

    #nav-mobile-icon {
      display: block;
    }

  }
}
