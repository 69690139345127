// footer

footer {
  padding: 15px 35px 20px 295px;
  .social-media {
    float: right;
    height: 77px;

    img {
      width: 36px;
      height: 36px;
    }
  }
  .nav-footer {
    ul {
      list-style: none;
      overflow: hidden;
      margin: 0 0 0 0;
      padding: 10px 0 0 0;
      li {
        float: left;
        margin-right: 10px;
        a {
          color: $white;
        }
      }
    }
  }
  @media only screen and (max-width: $size-M) {
    padding: 0 15px 15px 15px;
    margin-top: 50px;
  }
  @media only screen and (max-width: $size-S) {
    margin-top: 10px;
  }
}