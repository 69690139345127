//
// Menu Pages CSS
//

.frame-type-menu_pages {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      text-decoration: none;
      color: $red;
      font-weight: 700;
      @include font-size(16);
      line-height: 1.5;

      &:hover {
        color: lighten($red, 10%);
      }
    }

  }

}