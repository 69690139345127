// nav service

.nav-service {

  ul {
    overflow: hidden;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      position: relative;
      display: flex;
      width: calc((100% / 5) - 2%);
      aspect-ratio: 1 / 1;
      //background: url("../Images/bg_menu_service.png") no-repeat;
      background-size: 100%;
      overflow: hidden;
      background: rgb(85,85,85);
      background: linear-gradient(90deg, rgba(85,85,85,1) 0%, rgba(102,102,102,1) 50%, rgba(85,85,85,1) 100%);

      clip-path: polygon(
                      0% 0%,
                      100% 0%,
                      100% 90%,
                      90% 100%,
                      10% 100%,
                      0% 100%,
                      0% 10%);

      &:hover {
        background: $red;
      }

      a {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        justify-items: center;
        color: $white;
        text-align: center;
        @include font-size(18);
        font-weight: 700;

        &:hover,
        &.active {
          background-image: url("../Images/bg_menu_service_act.png");
          background-size: 100%;
        }
      }

      @media only screen and (max-width: $size-XL2) {
        width: calc((100% / 5) - 1%);

        a {
          @include font-size(14);
        }
      }
    }

    @media only screen and (max-width: $size-M) {
      margin: 5px 0 10px 0;
    }
  }

  @media only screen and (max-width: $size-S) {
    order: 3;
    margin-top: 20px;

    ul {
      display: block;
      padding: 0 10px;

      li {
        margin: 0 0 2px 0;
        aspect-ratio: auto;
        width: auto;
        clip-path: none;

        a {
          display: block;
          height: auto;
          line-height: 40px;
          background: $red;
          text-align: left;
          @include font-size(18);
          padding-left: 20px;
          width: 100%;
        }
      }
    }
  }
}
