// content welcome

section.content-welcome {
  position: relative;
  height: 221px;

  // Old CSC way
  .csc-default {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    //max-width: 570px;
    &:first-child {
      opacity: 1;
    }
    &.active {
      z-index: 5;
    }
    &.next {
      z-index: 6;
    }
  }

  .csc-textpic-intext-right-nowrap .csc-textpic-text {
    position: relative;
    background: none repeat scroll 0 0 white;
    height: auto;
    margin-right: 240px;
    padding: 20px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -22px;
      /*bottom: 0;*/
      background: url("../Images/white_box_bottom.png") no-repeat right top;
      width: 100%;
      height: 22px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: $size-XS) {
    //height: 202px;
    .csc-textpic-intext-right-nowrap-240 .csc-textpic-imagewrap {
      img {
        width: 110px;
        height: auto;
      }
    }
    .csc-textpic-intext-right-nowrap-240 .csc-textpic-text {
      margin-right: 0;
      width: auto;
      height: 150px;
      position: inherit;
    }
  }

  @media only screen and (max-width: $size-M) {
    .ce-intext.ce-right .ce-gallery {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

  // new FSC way
  .frame-default {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    //max-width: 570px;
    &:first-child {
      opacity: 1;
    }
    &.active {
      z-index: 5;
    }
    &.next {
      z-index: 6;
    }
  }

  .ce-bodytext {
    position: relative;
    background: none repeat scroll 0 0 white;
    height: 169px;
    margin-right: 240px;
    padding: 20px 20px 0 20px;

    p {
      height: 118px;
      overflow-y: auto;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -22px;
      /*bottom: 0;*/
      background: url("../Images/white_box_bottom_large.png") no-repeat right top;
      width: 100%;
      height: 22px;
    }
    p:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: $size-S) {
      margin-right: 0;
      width: auto;
      height: auto;
      position: inherit;
      margin-top: 20px;

      p {
        height: auto;
      }
    }

  }


  .ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
    overflow: inherit !important;
  }

  @media only screen and (max-width: $size-S) {
    height: auto;

    .frame-default {
      position: relative;
      margin-bottom: 40px;
    }

    .ce-textpic {
      position: relative;

      figure {
        position: relative;
        z-index: 9;
      }

      img {
        width: 110px;
        height: auto;
      }
    }

  }


}

// special handling on page "mitarbeiter" due to slider element
.page-id-70 {

  @media only screen and (max-width: $size-S) {

    section.content-welcome {
      height: 210px;

      .frame-default {
        position: absolute;
      }

      .ce-bodytext {
        min-height: 150px;
      }
    }

    .content-main,
    .content-bottom-bar {
      display: none;
    }
  }

  @media only screen and (max-width: $size-XS) {
    section.content-welcome {
      height: 315px;
    }
  }
}
